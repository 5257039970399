	/* Coded with love by Mutiullah Samim */
  body,
  html {
    margin: 0;
    padding: 0;
    height: 100%;
    background: #fafafa !important;
    font-family:Circular Std Book !important;
    
  }

  .user_card {
  
    margin-top: 50px;
    margin-bottom: auto;
    background: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    //-webkit-box-shadow: 0 4px 100px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 10px 80px 0 rgba(11, 10, 35, 0.06);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

  }
  .brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -55px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
  }
  .brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
  }
  .form_container {
    margin-top: 60px;
  }
  .login_btn {
    width: 100%;
    background: #36f !important;
    color: white !important;
  }
  .login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }
  .login_container {
    padding: 0 2rem;
  }
  .input-group-text {
    //background: #36f !important;
    background: #fff !important;
    color: white !important;
    //border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
    border: solid 1px #b0b7c3;
  
  }
  .input_user,
  .input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #36f !important;
  }
  .Logo {
    width: 205.5px;
    height: 41.2px;
    flex-grow: 0;
    object-fit: contain;
  }
  .input-group{
    //padding: 10px 24px !important;
  }
  .DontHaveAnAccount {
    height: 24px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Circular Std Book;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #323b4b;
  }
  .Create-an-Account {
    height: 30px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Circular Std Book;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #36f;
  }
  .ForgotPassword {
    width: 129px;
    height: 24px;
    flex-grow: 0;
    font-family: Circular Std Book;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: right;
    color: #747474;
  }

  .form-outline .form-control ~ .form-notch .border-error-color{
    border-color: #c13818;
    //border-width: unset;
  }
  .form-outline .form-control ~ .form-notch .border-error-color-midd{
    border-bottom-color: #c13818;
    //border-width: unset;
  }
  .form-outline .form-control ~ .form-label.label-color{
    color: #c13818;
  }

.Home {
  //width: 164px;
  height: 28px;
  flex-grow: 0;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  font-family: Circular Std Medium;
}
.Analytics {
  width: 43px;
  height: 28px;
  flex-grow: 0;
  font-family: Circular Std Book;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}
.label-header {
  width: auto;
  height: 40px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  border-radius: 8px;
  background-color: #f6f8fb;
}
.publisherBalance {
  // width: 43px;
  //height: 40px;
  flex-grow: 0;
  font-family: Circular Std Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #323b4b;
}

.notification {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}
.Icon-header {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border-radius: 20px;
  background-color: #f6f8fb;
}
.Vector {
  width: 16.6px;
  height: 18px;
  border: solid 1.5px #a0a0a0;
}

img.heroicons-outlinebell {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  object-fit: contain;
  border-radius: 16px;
}
.div {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.headerNav {

  // width: 252px;

  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  padding: 0;
  margin-right: 2px;
}

.widgetsSpan {

  flex-grow: 0;
  font-family: Circular Std Bold;
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: -0.76px;
  text-align: center;
  color: #323b4b !important;
}
.widgetImpressions {
  

  flex-grow: 0;
  font-family: Circular Std Book;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
}
.Right {
  width: 69.4px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
}

.captionGreen {

  flex-grow: 0;
  font-family: Circular Std Book;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1f8b24 !important;
}

.captionRed {

  flex-grow: 0;
  font-family: Circular Std Book;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #da100b !important;
}
.Trending {

  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 6px;
  border-radius: 32px;
  background-color: #f6f8fb;
  margin-right: 35px;
}
.Less-than {
  flex-grow: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 15px;
  border-radius: 32px;
  background-color: #f6f8fb;
   margin-right: 10px;
}


.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {

  //padding: 10px;
}
.col-lg-4, .col-sm-4{
  padding: 10px;
}
.col-lg-3, .col-sm-3{
  padding: 10px;
}
.heroicons-outlineadjustments-vertical {
  width: 24px;

  object-fit: contain;
}
.addFilter{
  display: flex;
  flex-direction: row;
  font-size: 30px;
  padding: 5px;
  width: 400px;
}
.card{
 box-shadow: none;
}

.customSampleBtn{
  height: 26px !important;
}
.buttonShape {
  width: 106px;
  height: 26px;
  //height:fit-content !important;
  flex-grow: 0;
  padding: 0 0 0 12px;
  border-radius: 23px;
  background-color: #ebebeb;
  border: none;
}
.Sample {
  width: 55px;
  height: 11px;
  margin: 11.9px 11.1px 14.1px 0;
  font-family: Circular Std Book;
  //font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.Inputs {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 48px;
  padding: 18px 26px;
}

.Small-widget {
  //height: 331.6px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 28px;
  padding: 0;
}


.Frame-1996 {
  height: 37.2px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  padding: 0;
}
.shapeDiv {
  width: 150px;
  height: 37px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 0;
}
.Rectangle-1305 {
  width: 23.9px;
  height: 37px;
  margin: 0 0 0 11.1px;
  padding: 12px 8px 12px 4.9px;
  background-color: #cacaca;
}
.next-1 {
  width: 11px;
  height: 13px;
  padding: 0.4px 0.7px 0.5px 0.9px;
  transform: rotate(-180deg);
}
.Vector {
  width: 9.4px;
  height: 12px;
  background-color: #fff;
}
.Checkboxes {
  width: 18px;
  height: 18px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 11.3px;
  padding: 11.3px;
  border-radius: 4.5px;
 // box-shadow: 0 1.1px 1.1px 0 rgba(0, 0, 0, 0.12), 0 0 0 1.1px rgba(0, 0, 0, 0.64), 0 2.3px 5.6px 0 rgba(103, 110, 118, 0.08);
  background-color: #fafafa;
}
.Web-Input-Field {
  width: 285px;
  //height: 69.2px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
  background-color: #fff;
}



.button-highlight {
  border-radius: 24px;
  box-shadow: 0 2px 5px 0 rgba(213, 217, 217, 0.5);
  color: #fff;
  padding: 3px 8px;
  line-height: initial;
  text-transform: capitalize;
  opacity: 1;
  transition: all 0.3s ease;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: initial;
  position: relative;
  color: #0d6efd;
  overflow: hidden;
  cursor: pointer;
}
.text-decoration-none {
  text-decoration: none!important;
}
.p-0 {
  padding: 0!important;
}

.fs-15 {
  font-size: 15px;
}
.bg-green {
  background-color: #259438 !important;
}


.button-shape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background-color: #33b249;
  transform: translate(-150%);
  transition: all 0.5s ease;
  z-index: -1;
}

.Rectangle-shape {

  width: 24.9px;
  height: 26px;

  // margin: 0 0 0 4.1px;
  border-radius: 0px 23px 23px 0px;
}
.css-1p3m7a8-multiValue {
  
  background-color:black !important;
  color: #fff;
  border-radius: 100px !important;
}
.css-wsp0cs-MultiValueGeneric {
  color:#fff !important;
}
.css-wsp0cs-MultiValueGeneric {
  
  font-size:14px !important;
  padding:6px !important;
  padding-left:10px !important;

}

.css-tj5bde-Svg {

 // display:none !important;
}
.css-12a83d4-MultiValueRemove{
  padding-right:5px !important;
  border-radius:100px!important;
}
.css-1hb7zxy-IndicatorsContainer div:nth-child(3){
  display:none;
}
.css-1hb7zxy-IndicatorsContainer span:nth-child(2){
  display:none;
}

.saveButton{

  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 11px 24px;
  border: solid 1px #c1c7d0;
  border-radius: 8px;
  background-color: #36f;
  color: #fff;
  font-size:16px;
}
.cancelButton{

  height: 48px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 11px 24px;
  border-radius: 8px;
  border: solid 1px #c1c7d0;
  background-color: #fff;
  color: #809fb8;
  font-size:16px;
}
.chevron-down{
  float:right;
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}
.Additional-payment-methods {
  height: 28px;
  flex-grow: 1;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}
.Account-Type-Advertiser {
  height: 24px;
  font-size: 16px;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  font-weight:500;
}






/* Style the tab */
.tab {
  overflow: hidden;
  //border: 1px solid #ccc;
  //background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 20px;
  color: #747474;
  font-weight: 500;


}



/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.Edit {
  width: 81px;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #fff;
  cursor: pointer;
}

.activeprofile{
  color: #4AA137 !important;
  border-bottom: 3px solid #4AA137 !important;
}
.newRect{
    overflow: hidden;
    border-radius: 52px;
    width: 100px;
    height: 100px;
    cursor:pointer;
}
.rectPro{
   top: -26px;
    position: relative;
}
.tablinks{
  background-color: transparent !important;
  text-decoration: none;
  padding-left:1.5rem !important;
  padding-right:1.5rem !important;
}

.Welcome-Ahmed-madhoun {

  //height: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  text-align: left;
  color: #323b4b;

  word-wrap: break-word;
  white-space: break-spaces;
}
.Profile {

  height: 24px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  color: #323b4b;
}

.avatarImg{

  width:22px !important;
  height:22px !important;
}
.ProfileGo{

  margin-left: 114px !important;
}

.balanceGo{
  margin-left: 5rem !important;
  width:54px;
  height: 28px;
  text-align:center;
  flex-grow: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border-radius: 3px;
  border: solid 1px #d2d3d6;
  background-color: #f6f8fb;
  font-family:Circular Std Medium;
  font-weight: 600;

}

.nav-item dropdown .nav-link{
  background-color: inherit;
}

.css-9jq23d {

   color: #fff !important;
} 

.edqCNR {
  
  padding:0px !important;
}

a.nav-link:hover {
     background-color: transparent; 
}
.calenderRang{
    background: rgb(255, 255, 255);
    cursor: pointer;
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 48px; 
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: #fff;
    font-size:16px;
    color:#323b4b;
    gap:5px;
}

span#camp_span:hover {
  border: none !important; 
}
div#camp_id:hover {
  border: none !important; 
}
div#search_scroll:hover {
  border: none !important; 
}


div#search_scroll:active {
  border: none !important; 
}
div#camp_id:active {
  border: none !important; 
}
span#camp_span:active {
  border: none !important; 
}

div#search_scroll:visited {
  border: none !important; 
}
div#camp_id:visited {
  border: none !important; 
}
span#camp_span:visited {
  border: none !important; 
}
.search_scroll{
  font-size:16px;
}

.lineGraphButton{
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;
  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
}

.modal-content {   
    width: 600px !important;
}



.highcharts-label.highcharts-tooltip.highcharts-color-undefined {
    background: #fff;
    width: 177px;
    height: 10px;
}

.jKVmtq{
  font-size:9px;
}
.hkqAST{
 font-size:9px; 
}
.bnCaEe{
  font-size:9px;
}
.hHvHGD{
   font-size:9px;
}

.asc{
margin-left:auto;
}
.desc{
margin-left:auto;
}

.iconSort{
  height: 16.6px;
  width:16.6px;
}

.pageTitle{
  
  font-size:30px;
  font-weight:bold;
  color:#323b4b;
}

.campDropFilter{
  width:90px !important;
  height: 48px;
  font-size: 16px !important;
  background-color: #f6f8fb !important;
  border-radius: 12px !important;
  //border: solid 1px rgba(0, 0, 0, 0.12) !important;
  border: solid 1px rgba(0, 0, 0, 0.12) !important;
  color: #323b4b !important;
  text-align:center;
}
.actionButton{
  
  border: none !important;
  outline: none;
  boxShadow: none;
  borderRadius: 0rem;
}
.kUMlDB{
  font-size:15.5px !important;
}

.gnBnkV{
  font-size:15.5px !important;
}

.idpvzF{  
  background-color:inherit !important;
}

.edqCNR{  
  background-color:inherit !important;
}
.sectionStyling{
  background-color:white !important;
  //padding-left:10px !important;
  //padding-right:10px !important;
  margin-top:20px !important;
}

.sectionMarginTop{
  background-color:white !important;
  //padding-left:10px !important;
  //padding-right:10px !important;
  margin-top:-23px !important;
}
.sectionStylingWhite{
   background-color:white !important;

}

.marginTableHeaderContentLeft{
  margin-left:-23px;
 
}

.marginTableHeaderContentRight{

  margin-left:23px;
  text-align: right;
  padding-right:5px !important;
}

.tablePadding{
  padding-left: 10px;
  padding-right: 10px;
}
.dropdownCardPadding{
  padding-right: 10px;
}

.custom-payment-method-label{
  width:100%;
  padding-left:1.5rem;
  text-align:left;
  font-size:16px;
}

.custom-funds-card{
  box-shadow:none !important;
  background:#fff !important;
  border:none !important;
}

.selected-pay{
  background:#fff !important;
}

.custom-font{
  font-family: Circular Std Medium !important;
}

.custom-mb{
  margin-bottom:1.5rem;
}

.custom-card-padding{
  padding:12px 12px;

}

.daterangepicker .ranges {
  
  font-family: Circular Std Book;
  font-weight: 600;

}

.custom-dropdown{
  width:213px;
}

.filterPaddingLeft{
  padding-left:1rem !important;
}
.paddingLeft0{
  padding-left:0px !important;
}

.Frame-1976 {
  width: 14.6px;
  height: 14.6px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27px;
  background-color: #d03a34;
  position: absolute;
  right: -5px !important;
  line-height: 11px;
}

.msgIconNo{

  font-size: 9px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  height:10px;
}

.nav-item{
  position:relative;
}
.profileText{

  font-family: Circular Std Medium;
  font-size: 16px;
  font-weight: 500;
  color: #747474;
}

.notificationHeader{
  background-color:#323b4b !important;
  color: #fff !important;
  font-family: Circular Std Medium;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
  //padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  white-space: nowrap;
  height:64px

}
.profileWordWrap{
  word-wrap: break-word;
  white-space: break-spaces;
  font-family: 'Circular Std Medium' !important;
}
.dropdownPopupHeader{
  display:flex;
  align-items:center;
}
.heroicons-outlinebell {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}

.Icon {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 6px;
  border-radius: 11px;
  background-color: #fff;
}
.css-v7duua:hover{
  border-radius:150px !important;
}

hr {
  margin-top: 12px !important;
  margin-bottom: 14px !important;
}


.available.in-range{
  border-radius: 50%;
}


.customBack{
  padding: 0.3rem 1rem 0.3rem !important;
}

.notifDescription{
  color:#a0a0a0;
  font-size:12px;
  font-family:'Circular Std Book'
}

.notifItem{
  border-bottom:1px solid #ededed !important;
}

.dropDownItemPadding{
  padding-top:0.5rem !important;
  padding-bottom:0.5rem !important;
  padding-left: 0.7rem !important;
}

.notifItemIcon{
  padding-right:0.5rem !important;
}

.viewAllItem{
  padding-bottom:0.75rem !important;
  padding-top:0.75rem !important;
}

.customActiveDate{
  background: #3366ff;
  width: 23px;
  height: 22px;
  border-radius: 100%;
}

.customActiveDateBG{
  background: rgba(51, 102, 255, 0.4);
  padding-top: 2px;
  padding-bottom: 1px;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  margin-left: 4px;
  // margin-top: -1px;
}

.customActiveEndDateBG{
  background: rgba(51, 102, 255, 0.4);
  padding-top: 1px;
  padding-bottom: 1px;
  border-top-right-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  // margin-bottom: -1px;
  padding-left:4px;
  margin-right:4px;
}

.customSaturday{
  background: rgba(51, 102, 255, 0.4);
  padding-top: 1px;
  // padding-bottom: 1px;
  border-top-right-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  // padding-left:4px;
  // margin-right:4px;
  margin-top: -1px;
}

.customSunday{
  background: rgba(51, 102, 255, 0.4);
  padding-bottom: 1px;
  border-top-left-radius: 100% !important;
  border-bottom-left-radius: 100% !important;
  margin-bottom: -1px;
  // padding-left:4px;
  margin-left:0px;
  margin-top: -1px;
  padding-bottom: 1px;
}

.customEndDate{
  background: rgba(51, 102, 255, 0.4);
  padding-top: 1px;
  padding-bottom: 1px;
  border-top-right-radius: 100% !important;
  border-bottom-right-radius: 100% !important;
  padding-left:4px;
  margin-right:4px;
  margin-top: -1px;
}

.customSundayME {
  background: rgba(51, 102, 255, 0.4);
  border-radius: 100%;
  padding-top: 2px;
  padding-bottom: 1px;
  margin-right: 3px;
}

.customSundayMEtwo {
  background: rgba(51, 102, 255, 0.4);
  border-radius: 100%;
  padding-top: 2px;
  padding-bottom: 1px;
  margin-right: 3px;
  padding-left: 1px;
}



.customWeekDay{
  background: rgba(51, 102, 255, 0.4);
  padding-bottom: 1px;
  border-top-left-radius: 100% !important;
  border-bottom-left-radius: 100% !important;
  margin-bottom: -1px;
  padding-left:4px;
  margin-left:0px;
  margin-top: -1px;
  padding-bottom: 1px;
  padding-top:1px;
}

.customActive{
  background:none !important;
}

.hideDownArrow{
  -webkit-appearance: none; 
  -moz-appearance: none; 
  border:none;
  text-align:center;
  background-color:#fff;
}

.hideDownArrow select::-ms-expand { 
  display: none; 
}

@media only screen and (min-width:1250px) and (max-width:1400px){
  #dateRangeLabel{
    font-size:14px !important;
  }
}

.customTrBorder{
  border-top:2px solid white;
  border-bottom:1px solid white;
}


.customDateSize{
  font-size:10px;
}


.daterangepicker.ltr.show-ranges.opensright.show-calendar{
  right:34px !important;
}

.sidebar-nav .nav-link.active > img {
  filter:contrast(10) !important;
}

.sidebar-nav .nav-link.active  > span{
  filter:contrast(10) !important;
}

.sidebar-nav .nav-link:hover span {
  filter:brightness(4.5);
}

.nav-group-toggle{
  // filter:contrast(0.6);
}


#myInput::-webkit-input-placeholder{
  color:#323b4b !important;
}

#showHide{
  cursor:pointer;
}

span.switch-handle.swcamp::before {
  margin: -7.3px 0 0 -13px !important;
}

span.switch-handle.swcamp::after {
  margin: -7.3px 0 0 -6px !important;
}



.customBorder{
  border-color:hsl(0, 0%, 80%) !important;
}

span.css-1u9des2-indicatorSeparator {
  display: none;
}



.customTooltipValue{
  float:right; 
  text-align:right; 
  color:white;
}


.customTooltipLabel{
  color:white;
  float:left;
  text-align:left;
  padding:0px !important;
  margin:0px !important;
}

.highcharts-label highcharts-tooltip highcharts-color-undefined{
  display:none;
}
.highcharts-label highcharts-tooltip {
  display:none !important;
}
.hoverNone:hover{
  background-color: transparent !important;
  text-decoration: none !important;
}

.highcharts-container{
  height: 100% !important;
}

.highcharts-legend {
  width: calc(100% - 11.1rem + 10px);
  max-height: 5rem;
  position: absolute;
  // overflow: auto;
  
  & * {
    position: relative !important;
    left: unset !important;
    top: unset !important;
  }
  & > div {
    & > div {
      display: flex;
      gap: 0.5rem;
    }
  }
  &::-webkit-scrollbar {
    width: 1rem;
  }
  &::-webkit-scrollbar-track {
    
    border-radius: 10rem;
  }

  
  
 
}
.chartSymbol {
  width: 1.1rem;
  height: 1.1rem;
  position: absolute;
  border-radius: 50%;
}
.highcharts-legend-item {
  border-radius: 1rem;
  & > path {
    display: none;
  }
  & > span {
    position: relative;
    border-radius: 8px;
    border: 1px solid #bababa;
    // padding: 1rem;
    padding: 8px 7px 8px 5px;
    display: flex;
    // gap: 0.5rem;
    align-items: center;
  }
  &:hover {
    background-color: #f5f5f5;
  }
}


// hide the tooltip in favor of showing our own new shadow tooltip 
g.highcharts-label.highcharts-tooltip.highcharts-color-undefined {
  display: none;
}


@media only screen and (max-width:1000px){
  .highcharts-legend{
    overflow: auto;
  }
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined{
  background-color:none !important;
  border:none !important;
}


.switch-label-camp {
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  text-transform: uppercase;
  background: #e5e7ea;
  border-radius: inherit;
}
.switch-input:checked ~ .switch-label-camp {
  background: #4aa137;
}

.cardRadioInput{
  height:19px;
  width:25px;
  marginLeft:4px;
}
.cardRadio{
  height:21px;
  width:21px;
}



header {
  padding-left: 0px !important;
}

.container-lg {
  padding-left: 0px !important; 
  padding-right: 0px !important; 
}

.customPageHeading{
  color:#323b4b!important;
}

.customPaymentMethodBox{
  background: #f9f9f9;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.customPaymentMethodFooter{
  background: #fff;
}


 .TableCSS{
//   padding-left:1.5rem;
//   padding-right:1.5rem;
//   padding-top:1rem;
//   margin-bottom:1.8rem;
     margin-left: 10px;
 }


.rdt_Pagination{
  border-top:0px !important;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}

.customHeaderBorder{
  border-bottom:1px solid #cccccc !important;
}

.customPaddingLeft{
  margin-left:0.5rem !important;
}

.customPaddingRight{
  margin-right:1.8rem !important;
}




@media only screen and (max-width: 600px) {
  .highcharts-legend {
      left: 0 !important;
      margin-left: 0 !important;
      width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .highcharts-legend {
      overflow: auto;
  }
}


.customBtn{
  background-color: #059669;
  border: 1px solid rgba(0, 0, 0, .12);
  border-radius: 12px;
  color: #fff;
  flex-direction: row;
  font-family: Circular Std Medium;
  justify-content: flex-start;
  // padding: 9px 12px;
  border-radius:12px;
  font-size:16px;
}


@media only screen and (max-width:600px){
  .sidebar-nav {
      margin-top: 80px !important;
  }
  .header-brand{
  display:none !important;
  }

  .highcharts-legend {
    width: calc(100%);
    margin-left: 0rem !important; 
    left:0px !important;
  }

}

@media only screen and (min-width:700px) and (max-width:1100px){
  .highcharts-legend {
    left:0px !important;
  }
  
  .bgInputField{
    background-color: #f6f8fb !important;
  }
}

@media only screen and (max-width:600px){
  #displayFit{
    flex-wrap: wrap;
    margin-left: -0.75rem;
    padding-right: calc(var(--cui-gutter-x)* 0) !important;
    margin-top: -6px;
    --cui-gutter-x: 1.5rem;
    --cui-gutter-y: 0;
    display: flex;

  }
}

.eYqSoz {
  
  padding-right:0px !important;
}
.customSundayHover{
  border-top-left-radius:100% !important;
  border-bottom-left-radius:100% !important;
}

.customSaturdayHover{
  border-top-right-radius:100% !important;
  border-bottom-right-radius:100% !important;
}

@media screen and (max-width: 530px) {
  .ms-selectable {
      margin-bottom: 1.1rem !important;
  }
}


@media screen and (max-width: 530px) {
  .ms-selectable, .ms-list, .ms-selection {
      width: 100% !important;
  }
}
@media screen and (max-width:380px){
  .custom-dropdown{
    width:50%;
  }
}




.double_range_slider_box {
  position: relative;
  width: 500px;
  height: 200px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.double_range_slider {
  width: 90%;
  height: 10px;
  position: relative;
  background-color: #dddddd;
  border-radius: 20px;
}
.range_track {
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: #000;
}

.rangeInput {
  position: absolute;
  width: 100%;
  height: 5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  top: 50%;
  transform: translateY(-50%);
}

input::-webkit-slider-thumb {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  pointer-events: auto;
  -webkit-appearance: none;
  cursor: pointer;
  margin-bottom: 1px;
}
input::-moz-range-thumb {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  pointer-events: auto;
  -moz-appearance: none;
  cursor: pointer;
  margin-top: 30%;
}

.minvalue {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;    
  position: absolute;
  padding: 6px 15px;
  background: #fff;
  border-radius:8px;
  color: black;
  top: 0;
  transform: translate(0, 100%);
  left: 0;
  font-size: 1rem;
  transition: left 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: left, transform;
}
.maxvalue {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;    
  position: absolute;
  padding: 6px 15px;
  background: #fff;
  border-radius:8px;
  color: black;
  top: 0;
  transform: translate(0, 100%);
  right: 0;
  font-size: 1rem;
  transition: right 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: right, transform;
}


.noFocus:focus{
  box-shadow:none !important;
}


.graphLegendBtn{
  font-size:13px;
  display:flex;
  align-items:center;
}

.customSundayHover{
  border-top-left-radius:100% !important;
  border-bottom-left-radius:100% !important;
}

.customSaturdayHover{
  border-top-right-radius:100% !important;
  border-bottom-right-radius:100% !important;
}

@media screen and (max-width: 530px) {
  .ms-selectable {
      margin-bottom: 1.1rem !important;
  }
}


@media screen and (max-width: 530px) {
  .ms-selectable, .ms-list, .ms-selection {
      width: 100% !important;
  }
}

@media screen and (max-width:380px){
  .custom-dropdown{
    width:50%;
  }
}


.sidebar-nav .nav-link:hover img {
  filter:brightness(4.5);
}

.sidebar-nav .nav-link:hover svg {
  filter:brightness(4.5);
}

.sidebar-nav .nav-link:hover {
  border-radius: 12px;
  color:white;
}

.customHoverLink{
  color:#346CC8 !important;
}

.customHoverLink:hover{
  color:#346CC8 !important;
  text-decoration:underline !important;
}

.kgGExh, .euAsiH{
  font-size:15.5px !important;
}

.circleIcon {
    font-size: 9px;
    // padding-bottom: 1px;
    padding-top:0px;
}
.AnalyticsUnpaid {
    width: 62px;
  height: 28px;
  flex-grow: 0;
 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
  color:#a0a0a0;
}

.AnalyticsPaid {
    width: 58px;
  height: 28px;
  flex-grow: 0;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  border: solid 1px #65a30d;
  background-color: #ecfccb;
  color:#65a30d;
}

.sidebar-nav .nav-link.active{
  color:#ebeaeb !important;
}

a.nav-link.nav-group-toggle {
  color: rgba(255, 255, 255, 0.6);
}


.customFormControl{
  padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5ccd6;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 48px !important;
    border-radius: 12px;
    background-color: #f6f8fb !important;
}
.max-ad:checked ~ .switch-label {
    background: rgb(51, 178, 73) !important;
}

.css-o9wuxg-control{
  min-height:40px !important;
  max-width: 40px !important;
}

.css-1adihsu-control{
  min-height:50px !important;
}

.form-outline .form-control ~ .form-label{
  top:2px !important;
}

.customNumDrop{
  height:40px !important;
  padding:0.5rem 0.5rem !important;
}


.sidebar-nav .nav-link{
  color:#919EAB !important;
  height:48px;
}



// custom btns start 
.customAddBtn{
	color:#F6F8FB !important;
  background-color:#979797 !important;
  border:none !important;
  //box-shadow: 0px 3px 7px 0px #00000038;
  border-radius:12px !important;
  font-size:16px !important;
}


.customCancelBtn{
  color:#686565 !important;
  background-color: #FAFAFA !important;
  border:1px solid #C1C7D0 !important;
  border-radius:12px !important;
  font-size:16px !important;
  height:40px;
}


.customUpdateBtn{
  color:#fff !important;
  background-color: #4AA137 !important; 
  border: 1px solid #418D30;
  border-radius:12px !important;
  font-size:16px !important;
  height:40px;
}
// custom btn end 

.alignCenterImage{

  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
}
code {
  color:#687281;
}

.arrowBtn{
  background-color: #f1cb0c;
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.customSample{
  margin:auto !important;
  height:auto !important;
}
.customIconMR {
  margin-right: 18px;
}

.sidebar-nav .nav-group-items .nav-link {
  padding-left: 16px !important;
}

.form-outline .form-control:focus {
  box-shadow: none !important;
  border: none !important;
}

.form-outline .form-control ~ .form-notch .form-notch-leading {
  
  border-radius: 12px 0 0 12px !important;
}
.form-outline .form-control ~ .form-notch .form-notch-trailing {

  border-radius: 0 12px 12px 0 !important;
}

.highcharts-legend, .highcharts-no-tooltip{
  dispaly:none !important;
}

.highcharts-legend.highcharts-no-tooltip > :nth-child(3) {
    display: none;
}

@media (min-width: 768px) and (max-width: 1024px) {

  .highcharts-legend.highcharts-no-tooltip {
    margin-left: 6rem;
  }
}


.highcharts-legend.highcharts-no-tooltip {
  margin-left: 6rem;
}

.customLoadingBg{
  background-color:#28546a !important;
  border:none !important;
}



