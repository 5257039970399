
.switchCamp {
  position: relative;
  display: block;
  vertical-align: top;
  width: 42px;
  height: 20px;
  padding: 3px;
  margin: 0 10px 10px 0;    
  border-radius: 18px;
  cursor: pointer;
}

.switch {
  position: relative;
  display: block;
  vertical-align: top;
  width: 62px;
  height: 22px;
  padding: 3px;
  margin: 0 10px 10px 0;    
  border-radius: 18px;
  cursor: pointer;
}
.switch-input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.switch-label {
  position: relative;
  display: block;
  height: inherit;
  font-size: 12px;
  text-transform: uppercase;
  background: #898585;
  border-radius: inherit;     
}

.switch-label:before, .switch-label:after {
  position: absolute;
  top: 16%;
  -webkit-transition: inherit;
  -moz-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
}

.switch-label-list:before, .switch-label-list:after {

  top: 21% !important;  
}


.switch-label:before {
  content: attr(data-off);
  right: 11px;
  color: #fff;
}
.switch-label:after {
  content: attr(data-on);
  left: 14px;
  color: #FFFFFF;
  opacity: 0;
}
.switch-input:checked ~ .switch-label {
   background: #4aa137;
}
.switch-input:checked ~ .switch-label:before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
  opacity: 1;
}
.switch-handle {
  position: absolute;
  top: 4px;
  left: 3px;
  width: 30px;
  height: 20.5px;    
}
.switch-handle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -5.9px 0 0 -11px;
  width: 12px;
  height: 12px;
  // background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
  background:#fff !important;
  background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
  border-radius: 6px;
}
.switch-handle::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6.1px 0 0 -8px;
    width: 12px;
    height: 12px;
    // background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background:#fff !important;
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    border-radius: 6px;
    opacity: 0;
  }
.switch-input:checked ~ .switch-handle {
  left: 36px;
}

.switch-input:checked ~ .swcamp {
  left: 15px !important;
}
/* Transition
    ========================== */
.switch-label, .switch-handle {
  transition: All 0.3s ease;
  -webkit-transition: All 0.3s ease;
  -moz-transition: All 0.3s ease;
  -o-transition: All 0.3s ease;
}
.switch-input:checked ~ .switch-handle:before {
    opacity: 0;
  }
  .switch-input:checked ~ .switch-handle:after {
    opacity: 1;
  }

