#ff {
  width: 201.7px;
  height: 36.1px;
}

.Publisher-Account-details {
  height: 30px;
  align-self: stretch;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #809fb8;
}
img.Logo02 {
  width: 205.5px;
  height: 41.2px;
  flex-grow: 0;
  object-fit: contain;
}
.Sign-Up-Publisher02 {
  //width: 1440px;
  //height: 1415px;
  flex-grow: 0;
  padding: 69px 367px 150.8px 368px;
  background-color: #fff;
}
.Content {
  width: 705px;
  //height: 1195.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 10px 80px 0 rgba(11, 10, 35, 0.06);
  background-color: #fff;
}
.Web-Input-Field-F {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  padding: 11px 24px 11px 16px;
  border-radius: 12px;
  border: solid 1px #b0b7c3;
  background-color: #fff;
}
.Web-Input-Field {
  height: 80px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
  background-color: #fff;
}
.Row {
  height: 55px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
}
.form {
  //height: 824px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
}
.Label-Here {
  // width: 80px;
  // height: 24px;
  // flex-grow: 0;
  // font-family: CircularStd;
  // font-size: 16px;
  // font-weight: 500;
  // font-stretch: normal;
  // font-style: normal;
  // line-height: 1.5;
  // letter-spacing: normal;
  // text-align: left;
  // color: #323b4b;

  //font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
    font-size: 14px;
    line-height: 1.43;
    color: #222;
    background-color: #fff;
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

.Type-here {
  width: 99px;
  height: 24px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}
.Frame-1970 {
  height: 48px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 11px 16px;
  border-radius: 12px;
  border: solid 1px #c1c7d0;
  background-color: #fff;
}
.Input-Company {
  height: 24px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
  padding: 0;
  background-color: #fff;
}
.Frame-2009 {
  //height: 24px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}
.Are-you-registering-as-a-company {
  width: 253px;
  height: 24px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}
.Subtract {
  width: 19.2px;
  height: 16.2px;
  background-color: #003cf0;
}
.Yes {
  width: 25px;
  height: 23px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.No-I-individual {

  height: 23px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: left;
  color: #747474;
}
.label {
  width: 57px;
  height: 24px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 0;
}
.Icon {
  width: 11.8px;
  height: 9.1px;
  background-color: #fff;
}

.Checkbox-Radiobox {
  height: 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 0;
}

.img-abc {
  width: 20px;
  height: 17px;
  flex-grow: 0;
  object-fit: contain;
}
.A-Multi---Select-Radiobox {
  //width: 449px;
  height: 24px;
  flex-grow: 0;
 // font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}
.A-Multi---Select-Radiobox .text-style-1 {
  font-weight: 500;
}
.Rectangle-12 {
  width: 22px;
  height: 22px;
  border-radius: 16px;
 // background-color: #38cb89;
}


.Actions-2 {
  height: 56px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0;
}

.Web-Button {
  height: 50px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 9px 24px;
  border-radius: 12px;
  background-color: #36f;
}

.register-button {
  // width: 69px;
  height: 28px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}



.AKY{
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
  width: 14px;
  height: 14px;
  //font-family: CircularStd;
}

.form-outline .form-control ~ .form-notch .border-error-color{
  border-color: #c13818;
  //border-width: unset;
}
.form-outline .form-control ~ .form-notch .border-error-color-midd{
  border-bottom-color: #c13818;
  //border-width: unset;
}
.form-outline .form-control ~ .form-label.label-color{
  color: #c13818;
}

.Dont-have-an-account {
  height: 24px;
  align-self: stretch;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #323b4b;
}

.Create-an-Account {
  height: 30px;
  align-self: stretch;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #36f;
}

.Forgot-Password {
  //width: 129px;
  height: 24px;
  flex-grow: 0;
  //font-family: CircularStd;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #747474;
  
}



  body {
    
    background: #fff !important;
    
  }







