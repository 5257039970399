/*
CSS: Highchart Custom CSS - Jubna
Author: Nasbeer Ahammed
Author website: http://nasbeer.com/
version: 1.0
*/
.highcharts-figure, .highcharts-data-table table {
  min-width: 360px; 
  max-width: auto;
 /* margin: 1em auto;*/
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0 span{}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1 span{}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2 span{}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3 span{}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4 .highcharts-graph{display: none;}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4 span{}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-5 .highcharts-graph{display: none;}

.highchart-naz-tooltip-width {
  width: 150px;
  font-family: Circular Std Book!important;
}
.highcharts-data-table table {
font-family: Circular Std Book!important;
border-collapse: collapse;
border: 1px solid #EBEBEB;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0{

  z-index: 999;
  /* width: 125px; */
  /* margin-left: -17px; */

  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-0>span {

  margin-top: 2px !important;
  /* margin-left: -0px !important; */

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  padding-right: 6px;
}
.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1{

  z-index: 999;
  /* width: 90px; */
  /* margin-left: -30px; */

  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;

}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-1>span {

  margin-top: 2px !important;

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  padding-right: 6px;
}


.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2{

  z-index: 999;
  /* width: 85px; */
  /* margin-left: -37px; */

  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-2>span {

  margin-top: 2px !important;

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  /* margin-left: 5px !important; */
  padding-right: 6px;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3{

  z-index: 999;
  /* width: 90px; */
  /* margin-left: -39px; */

  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-3>span {

  margin-top: 2px !important;

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  padding-right: 6px;
}



.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4{

  z-index: 999;
  /* width: 90px; */
  /* margin-left: -36px; */

  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-4>span {

  margin-top: 2px !important;

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
  padding-right: 6px;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-5{

  z-index: 999;
  /* width: 90px; */
  /* margin-left: -30px; */

  height: 30px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;

  border-radius: 8px;
  border: solid 1px #cecece;
  background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-5>span {

  margin-top: 2px !important;
  /* margin-left: -3px !important; */

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
   padding-right: 6px;
}
/*
.highcharts-legend-item {
  padding: 1em;
  }
  .highcharts-legend-item > span {
  min-width:50%;
  padding:0;
  margin-left:20px;
  overflow: visible!important;
  }
  .highcharts-legend-item > span:after {
    content: "";
   
    min-width: 100%;
    background-color: rgba(142, 142, 142, 0.25);
    border: 1px solid #e3e3e3;
   
    padding: 1px 36px 1px;
    margin-top: -2px !important;
    margin: 0px !important;
    margin-left:-20px;
    z-index: -1;
    border-radius: 5px;
  }*/
li.highcharts-menu-item {
  font-size: 14px !important;
  padding: 5px !important;
  margin-left: 4px !important;
  margin-right:4px !important;
}
li.highcharts-menu-item:hover {
  font-size: 14px !important;
  padding: 5px !important;
  background-color: #f2913d !important;
  margin-left: 4px !important;
  margin-right:4px !important;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.highcharts-legend.highcharts-no-tooltip, .highcharts-legend-item.highcharts-line-series {
  z-index: 0 !important;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-1.highcharts-series-2 span {
  z-index: 0 !important;
}

.highcharts-tooltip-box {
  fill: #fff;
  fill-opacity: 1;
  stroke-width: 1;
  z-index: 9999 !important;
  box-shadow:2px 2px 2px 2px #f5f5f5;
  font-family: Circular Std Book!important;
 
}
.highcharts-legend-item {
  font-size: 14px !important;
  position: absolute;
  left: 8px;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-6 {
 
    z-index: 999;
    /* width: 120px; */
    /* margin-left: -18px; */


    height: 30px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    border-radius: 8px;
    border: solid 1px #cecece;
    background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-6>span {

  margin-top: 2px !important;
  /* margin-left: -12px !important; */

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-7 {

    z-index: 999;
    /* width: 120px; */
    /* margin-left: -21px;     */
    height: 30px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    border-radius: 8px;
    border: solid 1px #cecece;
    background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-7>span{

  margin-top: 2px !important;
  /* margin-left: -4px !important; */

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;

}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-8 {

    z-index: 999;
    /* width: 120px; */
    /* margin-left: -21px;     */
    height: 30px;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;

    border-radius: 8px;
    border: solid 1px #cecece;
    background-color: #f9f9f9;
}

.highcharts-legend-item.highcharts-line-series.highcharts-color-undefined.highcharts-series-8>span{

  margin-top: 2px !important;
  /* margin-left: -4px !important; */

  font-family: Circular Std Book !important;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a0a0a0;

}

.highcharts-xaxis-labels>text{

  font-size: 16px !important;
  font-family: Circular Std Book !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #323b4b;
}

.circleIcon{
  font-size: 9px;
}


.highcharts-label.highcharts-tooltip.highcharts-color-undefined {
    background: #fff;
    width: 184px;
    height: 60%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius:5px;
    /* /height: 210px; */
}

.highcharts-legend.highcharts-no-tooltip>div{
  left:14px !important;
  gap:10px !important;
}